// @flow
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';

type Props = {
  onLoginSuccess: Function,
  onLoginError: Function,
  api: Object
};

type State = {
  email: ?string,
  password: ?string
};

class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { email: null, password: null };
  }

  /**
   * Push form name : value pairs to local state
   */
  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /**
   * Submit the login request
   */
  handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, password } = this.state;
    const { api, onLoginSuccess, onLoginError } = this.props;
    if (!email || !password) {
      console.log('Missing email or password.');
      return new Error('Missing email or password');
    }
    console.log('Form submitted', event);
    console.log(`Logging in as ${email}`);

    api.login(email, password).then(res => {
      if (!res.ok) {
        return onLoginError(res);
      }
      return onLoginSuccess(res);
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h2>Login as Channel</h2>
        <input type="text" name="email" onChange={this.handleChange} />
        <input type="password" name="password" onChange={this.handleChange} />
        <Button type="submit">Login</Button>
      </form>
    );
  }
}

export default Login;
