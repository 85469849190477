// @flow
import type { User } from './common';

export default class Api {
  apiRoot: ?string;
  token: ?string;
  user: ?User;

  constructor(apiRoot: string, token: ?string, user?: User) {
    this.apiRoot = apiRoot;
    this.token = token;
    this.user = user || null;
  }

  getApiRoot = () => {
    return this.apiRoot;
  };

  setToken = (token: string) => {
    this.token = token;
  };

  getToken = () => {
    return this.token;
  };

  clearToken = () => {
    this.token = null;
  };

  setUser = (user: User) => {
    this.user = user;
  };

  getUserId = () => {
    return this.user ? this.user.uid : 0;
  };

  login = (email: string, pass: string) => {
    return this.post(`user/login`, { email: email, password: pass });
  };

  authenticateVideoUpload = () => {
    return this.get(`user/authenticate/videoUpload`);
  };

  getVideos = () => {
    const uid = this.getUserId();
    return this.get(`artist/${uid}/videos`);
  };

  postPost = (post: Object) => {
    return this.post('post', post);
  };

  get = (path: string) => {
    return fetch(`${this.apiRoot || ''}${path}`, {
      headers: {
        Authorization: this.getToken() || ''
      }
    }).then(res => res.json());
  };

  post = (path: string, data: Object) => {
    return fetch(`${this.apiRoot || ''}${path}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: this.getToken() || ''
      },
      method: 'POST',
      body: JSON.stringify(data)
    });
  };

  putAWS = (path: string, data: Object) => {
    return fetch(`${path}`, {
      headers: {
        'Content-Type': 'image/jpeg'
      },
      method: 'PUT',
      body: data
    });
  };
}
