// @flow
import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import type { Collection } from '../common';

type Props = {
  collection: ?string,
  collectionId: ?number,
  collections: Array<Collection>,
  handleChange: Function,
  handleSubmitForm: Function,
  isButtonActive: boolean,
  isFileComplete: boolean,
  isFormSubmitted: boolean,
  selectedThumbnail: number,
  selectThumbnail: Function,
  thumbnails: Array<string>,
  title: string
};

class PostBodyForm extends Component<Props> {
  render() {
    const {
      collection,
      collectionId,
      collections,
      handleChange,
      handleSubmitForm,
      isButtonActive,
      isFileComplete,
      isFormSubmitted,
      selectedThumbnail,
      selectThumbnail,
      thumbnails,
      title
    } = this.props;

    if (isFormSubmitted) return null;

    return (
      <div>
        <br />
        <strong>Collection</strong>
        <Select
          name="collection"
          value={collectionId}
          onChange={handleChange}
          options={collections.map(collection => {
            return {
              value: collection.id,
              label: `${collection.title} (#${collection.id})`
            };
          })}
        />
        <br />
        <Form>
          <Form.Field>
            <label>Title</label>
            <input
              name="title"
              onChange={handleChange}
              type="text"
              value={title}
            />
          </Form.Field>

          <Form.Field>
            <label>Body</label>
            <textarea name="body" onChange={handleChange} />
          </Form.Field>

          {thumbnails.map((screenshot, i) => (
            <Thumbnail
              alt={`Thumbnail #${i}`}
              isSelected={i === selectedThumbnail}
              key={i}
              onClick={() => selectThumbnail(i)}
              src={screenshot}
            />
          ))}

          <div>
            <Button
              disabled={!isFileComplete || isFormSubmitted}
              onClick={handleSubmitForm}
              primary={isButtonActive}
              size="massive"
              type="submit">
              Save video
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const Thumbnail = styled.img`
  border: 5px solid ${props => (props.isSelected ? 'lightblue' : 'white')};
  cursor: pointer;
  opacity: ${props => (props.isSelected ? 1 : 0.5)};
  width: 150px;
`;

export default PostBodyForm;
