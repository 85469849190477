// @flow
import React, { Component } from 'react';
import { Message, Icon, Progress } from 'semantic-ui-react';

type Props = {
  message: string,
  isLoading: boolean,
  isComplete: boolean,
  progress: number
};

class ProgressBar extends Component<Props> {
  render() {
    const { message, isLoading, isComplete, progress } = this.props;
    return (
      <div>
        {message && (
          <Message icon>
            {isLoading && <Icon name="circle notched" loading />}
            {isComplete && <Icon name="bullseye" color="green" />}
            <Message.Content>
              <Message.Header>
                {isLoading
                  ? 'Just a moment'
                  : isComplete ? 'Done' : 'Almost there'}
              </Message.Header>
              {message}
            </Message.Content>
          </Message>
        )}
        {progress > 0 && (
          <Progress percent={progress} autoSuccess progress size="small">
            {/* {videoProgress > 0 && videoProgress < 100
            ? `Uploading ${filename}...`
            : ''}
          {videoProgress === 100 ? `Upload complete for ${filename}` : ''} */}
          </Progress>
        )}
      </div>
    );
  }
}

export default ProgressBar;
