// @flow
import React, { Component } from "react";
import Helmet from "react-helmet";
import { Container, Divider } from "semantic-ui-react";
import DropzoneS3Uploader from "./DropzoneS3Uploader";
import ProgressBar from "./ProgressBar";
import VideoPlayer from "./VideoPlayer";
import PostBodyForm from "./PostBodyForm";
// import UploadDetails from './UploadDetails';
import { dataURItoBlob } from "../tools";
import type { VideoMeta, ThumbnailMeta, Collection } from "../common";

type Props = {
  api: Object,
  collections: Array<Collection>
};

type State = {
  body: ?string,
  collection: ?string,
  collectionId: number,
  complete: boolean,
  filename: string,
  isMetaComplete: boolean,
  isMetaUploading: boolean,
  isThumbnailComplete: boolean,
  isThumbnailUploading: boolean,
  isVideoComplete: boolean,
  isVideoUploading: boolean,
  metaError: string,
  selectedThumbnail: number,
  statusLoading: boolean, // show status spinner?
  statusMessage: string,
  thumbnail: ?ThumbnailMeta, // the thumbnail object from the api, uploadUrl, uri
  thumbnailError: string,
  thumbnails: Array<string>, // array of thumbnail base64 data uri strings
  title: ?string,
  video: ?VideoMeta, // the video object from the api, smilUrl
  videoError: string,
  videoFile: string,
  videoProgress: number
};

class Uploader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      body: "",
      collection: null,
      collectionId: 0,
      complete: false,
      filename: "",
      isMetaComplete: false,
      isMetaUploading: false,
      isThumbnailComplete: false,
      isThumbnailUploading: false,
      isVideoComplete: false,
      isVideoUploading: false,
      metaError: "",
      selectedThumbnail: 0,
      statusLoading: false,
      statusMessage: "",
      thumbnail: null,
      thumbnailError: "",
      thumbnails: [],
      title: "",
      video: null,
      videoError: "",
      videoFile: "",
      videoProgress: 0
    };
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  // store dropzone DOM ref
  dropzone = null;

  /**
   * We need our authorised upload links before we can accept any files
   */
  componentDidMount() {
    const { api } = this.props;
    if (!api.getToken()) return null;
    api.authenticateVideoUpload().then(res => {
      this.setState({ video: res.video, thumbnail: res.thumbnail });
    });
  }

  /**
   * Track form values in state
   */
  handleChange = (event: any) => {
    // if event is null its probably the clear event from collections
    // form react-select
    if (event === null) {
      this.setState({ collectionId: null });
      return false;
    }
    if (event.value) {
      this.setState({ collectionId: event.value, collection: event.name });
    }
    if (event.target) {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  /**
   * When the file is dropped on the 'zone
   */
  onDrop = (
    acceptedFiles: Array<any>,
    rejectedFiles: Array<any>,
    event: any
  ) => {
    acceptedFiles.forEach(file => {
      const url = URL.createObjectURL(file);
      this.setState({
        videoFile: url,
        filename: file.name,
        title: this.state.title === "" ? file.name : this.state.title // only update if blank
      });

      console.log("Accepted:", file.name, file);
    });
    rejectedFiles.forEach(file => {
      console.log("Rejected:", file.name, file);
    });
  };

  /**
   * getSignedUrl override called by react-dropzone-s3-uploader signing request
   */
  getSignedUrl = (file: Object, callback: Function) => {
    const uploadUrl = this.state.video
      ? this.state.video.uploadUrl
      : new Error("No upload URL");
    callback({
      signedUrl: uploadUrl
    });
  };

  /**
   * 0-100% progress events from the uploader
   */
  handleProgress = (progress: number) => {
    this.setState({
      videoProgress: progress,
      isVideoUploading: true,
      statusMessage: "Uploading video...",
      statusLoading: true
    });
  };

  /**
   * The video file uploaded successfully to S3.
   */
  handleFinishedUpload = (info: Object) => {
    console.log("File uploaded to S3 with filename", info.file.name);
    this.setState({
      isVideoUploading: false,
      isVideoComplete: true,
      statusMessage: "Video uploaded.",
      statusLoading: false
    });
  };

  handleUploaderError = (error: string, file: Object) => {
    console.log("S3 Upload error:", error);
    this.setState({
      isVideoUploading: false,
      statusMessage: "Error  uploading video: " + error,
      statusLoading: false
    });
  };

  /**
   * Promise to return the result of a thumbnail upload
   */
  uploadThumbnail = (): Promise<Object | Error> => {
    const { api } = this.props;
    const { thumbnail, thumbnails, selectedThumbnail } = this.state;
    return new Promise((resolve, reject) => {
      if (thumbnails.length) {
        if (!thumbnail) return reject(new Error("No thumbnail"));
        this.setState({
          statusMessage: "Uploading thumbnail...",
          statusLoading: true
        });
        api
          .putAWS(
            thumbnail.uploadUrl,
            dataURItoBlob(thumbnails[selectedThumbnail])
          )
          .then(res => resolve(res))
          .catch(err => reject(err));
      } else {
        return reject(new Error("No thumbnails to upload."));
      }
    });
  };

  /**
   * Post the form data and file paths to backend via API
   */
  postPost = (): Promise<Object | Error> => {
    const { api } = this.props;
    const { title, body, thumbnail, video } = this.state;
    return new Promise((resolve, reject) => {
      this.setState({
        statusMessage: "Sending video location to api...",
        statusLoading: true
      });
      return api
        .postPost({
          type: "video",
          title: title,
          body: body,
          thumbnail: thumbnail,
          video: video
        })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  };

  /**
   * When something captures a screenshot add it to app state
   */
  onCaptureImage = (image: string) => {
    this.setState(prevState => ({
      thumbnails: [...prevState.thumbnails, image]
    }));
  };

  selectThumbnailByIndex = (index: number) => {
    this.setState({ selectedThumbnail: index });
  };

  /**
   * Crude validation
   */
  validateForm = () => {
    const { isVideoComplete, isVideoUploading, title, body } = this.state;

    if (title === "") {
      alert("Please fill in a title for the video.");
      return false;
    }

    if (body === "") {
      alert("Please fill in the text body.");
      return false;
    }

    if (!isVideoComplete && !isVideoUploading) {
      alert(
        "Please upload a video. Drag it onto the strip at the top of the page."
      );
      return false;
    }

    if (!isVideoComplete && isVideoUploading) {
      alert("Please wait for the video to finish uploading...");
      return false;
    }

    return true;
  };

  /**
   * Let's see if we can submit
   */
  async handleSubmitForm(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    console.log("Submitting form...");

    // does it blend?
    if (!this.validateForm()) return false;

    try {
      let thumbUpload = await this.uploadThumbnail();
      if (thumbUpload.status !== 200) {
        this.setState({
          statusMessage: `${thumbUpload.status}: ${thumbUpload.statusText}`,
          statusLoading: false
        });
        alert("Thumbnail upload failed!");
        return false;
      }
      console.log("Uploaded thumb...", thumbUpload);

      let postPost = await this.postPost();
      if (postPost.status !== 200) {
        this.setState({
          statusMessage: `${postPost.status}:: ${postPost.statusText} ${
            postPost.body.error
          }`,
          statusLoading: false
        });
        alert("Posting video metadata to API failed.");
        return false;
      } else {
        this.setState({
          statusMessage:
            "Video and thumbnail uploaded. Video saved to database. Congratulations! 🎉 ",
          complete: true,
          statusLoading: false
        });
      }
      console.log("Notified backend of files.", postPost);
    } catch (e) {
      alert(e);
    }
  }

  /**
   * the path to the channel's bucket directory
   *
   * eg. /videos/toTranscode/dilbertthecorgi
   */
  getBucketDirectory = (video: VideoMeta): string => {
    let segments = video.uri.split("/");
    segments.pop();
    segments = segments.join("/");
    return segments;
  };

  /**
   * Parse the url and get the protocol and bucket hostname
   * eg. https://bucket.s3.amazon.com
   */
  getAWSBucketRootURL = (video: VideoMeta): string => {
    const videoUrl = parseUrl(video.uploadUrl);
    return `${videoUrl.protocol}//${videoUrl.hostname}`;
  };

  /**
   * Split the filepath and take the last segment as the filename
   */
  getFilenameFromUploadUrl = (video: VideoMeta): string => {
    let segments = video.uri.split("/");
    return segments[segments.length - 1];
  };

  /**
   * Figure out roughly what's happening to show the user
   */
  getStatusString = (videoProgress: number) => {
    let status = videoProgress > 0 && videoProgress < 100 ? "Uploading" : "";
    if (videoProgress === 100) status = "Uploaded";
    return status;
  };

  /**
   * Do something with the page title so the tab can show upload progress etc.
   */
  getComponentTitle = (
    videoProgress: number,
    isVideoUploading: boolean,
    isVideoComplete: boolean,
    filename: string
  ): string => {
    let componentTitle = isVideoUploading
      ? `${videoProgress}% ${filename} uploading...`
      : "[-] Video Uploader";
    if (isVideoComplete) componentTitle = `🎉 ${filename} uploaded.`;
    return componentTitle;
  };

  /**
   * Figure out how the dropzone should look
   */
  getDropzoneStyle = (
    videoProgress: number,
    isVideoComplete: boolean
  ): Object => {
    let dropzoneStyle = {
      height: "68px",
      width: "100%",
      background: "#f0f0f0"
    };
    if (videoProgress > 0) {
      dropzoneStyle = { ...dropzoneStyle, height: "0px", opacity: 0 };
    }
    if (isVideoComplete) {
      dropzoneStyle = { ...dropzoneStyle, display: "none", opacity: 0 };
    }
    return dropzoneStyle;
  };

  render() {
    const {
      body,
      collection,
      collectionId,
      complete,
      filename,
      isVideoComplete,
      isVideoUploading,
      selectedThumbnail,
      statusLoading,
      statusMessage,
      thumbnail,
      thumbnails,
      title,
      video,
      videoProgress
    } = this.state;

    const { collections } = this.props;

    if (!video || !thumbnail) {
      return <div>Requesting upload authorisation...</div>;
    }

    const bucketDirectory = this.getBucketDirectory(video);
    const componentTitle = this.getComponentTitle(
      videoProgress,
      isVideoUploading,
      isVideoComplete,
      filename
    );
    const dropzoneStyle = this.getDropzoneStyle(videoProgress, isVideoComplete);
    const suppliedFilename = this.getFilenameFromUploadUrl(video);
    const status = this.getStatusString(videoProgress);

    let activeButton = false;
    if (isVideoComplete && title !== "" && body !== "") {
      activeButton = true;
    }

    return (
      <Container>
        <Helmet>
          <title>{componentTitle}</title>
        </Helmet>
        <div className="twelve wide column">
          <h2>{filename.length ? `${status} ${filename}` : "Upload a file"}</h2>
          <ProgressBar
            isComplete={complete}
            isLoading={statusLoading}
            message={statusMessage}
            progress={videoProgress}
          />

          <DropzoneS3Uploader
            ref={node => {
              this.dropzone = node;
            }}
            multiple={false}
            onDrop={this.onDrop}
            onFinish={this.handleFinishedUpload}
            onProgress={this.handleProgress}
            onError={this.handleUploaderError}
            s3Url={bucketDirectory}
            style={dropzoneStyle}
            upload={{
              autoupload: true,
              s3path: bucketDirectory,
              getSignedUrl: this.getSignedUrl,
              contentDisposition: "auto",
              scrubFilename: filename => suppliedFilename,
              uploadRequestHeaders: {}
            }}
          />

          <PostBodyForm
            collection={collection}
            collectionId={collectionId}
            collections={collections}
            handleChange={this.handleChange}
            handleSubmitForm={this.handleSubmitForm}
            isButtonActive={activeButton}
            isFileComplete={isVideoComplete}
            isFormSubmitted={complete}
            selectedThumbnail={selectedThumbnail}
            selectThumbnail={this.selectThumbnailByIndex}
            thumbnails={thumbnails}
            title={title || ""}
          />
        </div>

        {this.state.videoFile && (
          <VideoPlayer
            file={this.state.videoFile}
            isFormSubmitted={complete}
            onCaptureImage={this.onCaptureImage}
          />
        )}

        {/* <UploadDetails video={video} thumbnail={thumbnail} /> */}

        <Divider />
      </Container>
    );
  }
}

const parseUrl = url => {
  var parser = document.createElement("a");
  parser.href = url;
  return parser;
};

export default Uploader;
