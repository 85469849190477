// @flow
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import Login from './Login';
import Uploader from './Uploader';
import Api from '../Api';
import { setCookie, getCookie } from '../cookie';
import type { User, Video, Collection } from '../common';

const COOKIE = 'spinner';

type Props = {};

type State = {
  error: ?string,
  api: Object,
  user: ?User,
  videos: Array<Video>,
  collections: Array<Collection>
};

class App extends Component<Props, State> {
  apiRootEnv: string = process.env.REACT_APP_API_ROOT || '';

  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      user: null,
      api: new Api(this.apiRootEnv, null),
      collections: [],
      videos: []
    };
  }

  componentDidMount() {
    this.checkToken();
  }

  componentDidUpdate() {
    this.checkToken();
  }

  /**
   * handle a success response from Login component
   */
  onLoginSuccess = (loginResponse: Object) => {
    const { api } = this.state;
    loginResponse.json().then(res => {
      const user: User = { ...res.user, token: res.authKey };
      this.setLoggedIn(user);
    });
  };

  /**
   * Handle an error response from Login component
   */
  onLoginError = (error: Object) => {
    let msg = `${error.status} ${error.statusText}`;
    let api = new Api(this.apiRootEnv, null);
    if (error.message) msg += ` ${error.message}`;
    this.setState({
      user: null,
      error: msg,
      api: api
    });
  };

  /**
   * Save the token
   */
  setLoggedIn = (user: User) => {
    const { api } = this.state;
    if (!api.getUserId()) {
      this.setState(
        { api: new Api(this.apiRootEnv, user.token, user) },
        this.getChannelData
      );
    }
    this.setState({ user: user, error: null });
    setCookie(COOKIE, JSON.stringify(user), 1);
  };

  setLoggedOut = (e: Event) => {
    e.preventDefault();
    this.setState({
      api: new Api(this.apiRootEnv, null),
      user: null,
      error: null
    });
    setCookie(COOKIE, null);
  };

  /**
   * Let's use the token if we have one
   */
  checkToken = () => {
    const { user } = this.state;
    if (!user) {
      let storedUser = getCookie(COOKIE);
      if (storedUser) {
        this.setLoggedIn(JSON.parse(storedUser));
      }
    }
  };

  getChannelData = () => {
    const { api } = this.state;
    api.getVideos().then(res => {
      const videos = res.videos;
      const collections = res.collections;
      this.setState({
        videos,
        collections
      });
    });
  };

  render() {
    const { user, error, api, collections } = this.state;
    if (!user) {
      return (
        <div>
          <Login
            onLoginSuccess={this.onLoginSuccess}
            onLoginError={this.onLoginError}
            api={api}
          />
          <div>{error}</div>
        </div>
      );
    }

    return (
      <div className="App">
        <Header>
          <Title>Video Uploader 2000</Title>
          <Identity>
            Uploading to channel #<strong>{user.uid}</strong> ({user.email}){' '}
            <Button onClick={this.setLoggedOut} size="mini">
              Logout
            </Button>
          </Identity>
        </Header>
        <PageBody>
          <div>{error}</div>
          <Uploader api={api} collections={collections} />
        </PageBody>
      </div>
    );
  }
}

// TODO move these out to presentational components
const Header = styled.div`
  height: 72px;
  padding: 24px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;

const Identity = styled.span`
  float: right;
`;

const Title = styled.span`
  float: left;
  font-weight: bold;
  font-size: 20px;
`;

const PageBody = styled.div`
  margin-top: 56px;
`;

export default App;
