//  @flow
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';

type Props = {
  file: string,
  isFormSubmitted: boolean,
  onCaptureImage: Function
};

type State = {
  isVisible: boolean
};

class VideoPlayer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isVisible: false };
  }

  componentDidMount() {
    this.doScreenshots();
  }

  toggleVisible = () => {
    console.log('visible', this.state.isVisible);
    this.setState(prevState => ({
      isVisible: !prevState.isVisible
    }));
  };

  /**
   *  Don't interrupt video player unless file changes
   */
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (nextProps.file !== this.props.file) {
      return true;
    }
    return false;
  }

  handleCaptureScreenshot = () => {
    this.capture();
  };

  /**
   * Capture the video output and draw it on a canvas,
   * extracting the base64 image data url to pass to it's parent
   */
  capture = () => {
    const canvas = this.refs.canvas;
    const video = this.refs.video;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext('2d')
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const image = canvas.toDataURL('image/jpeg', 1);
    this.props.onCaptureImage(image);
  };

  /**
   * Grab some screenshots automatically-ish :/
   */
  doScreenshots = () => {
    const video = this.refs.video;
    setTimeout(() => {
      this.capture();
    }, 1000);
    setTimeout(() => {
      video.currentTime = video.duration / 10;
    }, 1200);
    setTimeout(this.capture, 1500);
    setTimeout(() => {
      video.currentTime = video.duration / 5;
    }, 1700);
    setTimeout(this.capture, 2000);
    setTimeout(() => {
      video.currentTime = video.duration / 2;
    }, 2200);
    setTimeout(this.capture, 2500);
  };

  render() {
    const { file } = this.props;

    if (!file) {
      return <div>Waiting for video...</div>;
    }

    let style = {
      height: '600px'
    };

    return (
      <div style={style}>
        <video
          width={500}
          ref="video"
          src={`${file}`}
          controls={true}
          type="video/mp4"
        />
        <canvas
          style={{ display: 'none' }}
          width={1920}
          height={1080}
          ref="canvas"
          id="canvas"
        />
        <div>
          <Button onClick={this.handleCaptureScreenshot} type="submit">
            Capture thumbnail
          </Button>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
